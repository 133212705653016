.statisticsForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border: 1px dotted #ddd;
  border-radius: 10px;
  padding: 20px;
  gap: 10px;
}

.select {
  width: 120px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.numberInput {
  width: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  text-align: center;
}

.statisticsList {
  margin-top: 20px;
  width: 100%;
}

.statisticsList table {
  width: 100%;
  border-collapse: collapse;
}

.statisticsList table th {
  background-color: #f9f9f9;
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.statisticsList table td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.deleteButton {
  background-color: #fff;
  border: 1px solid #ff6b6b;
  color: #ff6b6b;
  margin-left: 8px;
}

.deleteButton:hover {
  background-color: #ff6b6b;
  color: #fff;
}

.active {
  background-color: #d5a10e;
  color: #fff;
}

.inActive {
  background-color: #ddd;
  color: #999;
}

.editButton {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}

.editInput {
  width: 80px;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
  text-align: center;
}

.editInput:hover {
  border-color: #ddd;
}

.editInput:focus {
  border-color: #d5a10e;
  outline: none;
}

.yearCell {
  font-weight: 500;
}

.deleteButton {
  background-color: #fff;
  border: 1px solid #ff6b6b;
  color: #ff6b6b;
  margin-left: 8px;
}

.deleteButton:hover {
  background-color: #ff6b6b;
  color: #fff;
}
