.tabMenu {
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.tabButton {
  padding: 5px 30px;
  margin-right: 5px;
  border-radius: 10px 10px 0 0;
  background: #ddd;
  cursor: pointer;
}

.activeTab {
  border: 1px solid #d5a10e;
  background-color: #d5a10e;
  color: #fff;
  font-weight: bold;
}

.inActiveTab {
  color: #999;
}

.historyForm {
  margin-bottom: 20px;
}

.inputGroup {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.historyList table {
  width: 100%;
  border-collapse: collapse;
}

.historyList th,
.historyList td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.historyList th {
  background-color: #f5f5f5;
}
