.pastor-form {
  margin-bottom: 20px;
}

.pastor-form .input-group {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.pastor-form input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pastor-list table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.pastor-list th,
.pastor-list td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
}

.pastor-list th {
  background-color: #f5f5f5;
}

.pastor-list button {
  margin: 0 5px;
}

.pastorsForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border: 1px dotted #ddd;
  border-radius: 10px;
  padding: 20px;
}

.pastorsForm input {
  margin: 0 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.pastorsForm input[type='text'] {
  width: 100%;
}

.pastorsForm select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 10px;
  width: 120px;
}

.pastorsList {
  margin-top: 20px;
  width: 100%;
}

.pastorsList table {
  width: 100%;
  border-collapse: collapse;
}

.pastorsList table th {
  background-color: #f9f9f9;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.pastorsList table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.editButton {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}
.editButton:hover {
  background-color: #f9f9f9 !important;
  color: #000 !important;
}

.deleteButton {
  background-color: #fff;
  border: 1px solid #ff6b6b;
  color: #ff6b6b;
  margin-left: 8px;
}

.deleteButton:hover {
  background-color: #ff6b6b !important;
  color: #fff;
}

.active {
  background-color: #d5a10e;
  color: #fff;
}

.inActive {
  background-color: #ddd;
  color: #999;
}

.editInput {
  width: 100%;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.editInput:hover {
  border-color: #ddd;
}

.editInput:focus {
  border-color: #d5a10e;
  outline: none;
}
