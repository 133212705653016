.historyForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  border: 1px dotted #ddd;
  border-radius: 10px;
  padding: 20px;
}

.historyForm input {
  margin: 0 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.historyForm input[type='text'] {
  width: 100%;
}

.button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.editButton {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
}
.editButton:hover {
  background-color: #f9f9f9 !important;
  color: #000 !important;
}

.deleteButton {
  background-color: #fff;
  border: 1px solid #ff6b6b;
  color: #ff6b6b;
  margin-left: 8px;
}

.deleteButton:hover {
  background-color: #ff6b6b !important;
  color: #fff;
}

.historyForm button.active {
  background-color: #d5a10e;
  color: #fff;
}

.historyForm button.inActive {
  background-color: #ddd;
  color: #999;
}

.decadeDivider {
  background-color: #f5f5f5;
}

.decadeTitle {
  padding: 15px !important;
  font-size: 1.3em;
  font-weight: bold;
  color: #d5a10e;
  text-align: left !important;
  background-color: #f9f9f9;
}

.historyList {
  margin-top: 20px;
  width: 100%;
  position: relative;
}

.historyList table {
  width: 100%;
  border-collapse: collapse;
}

.historyList table th {
  background-color: #f9f9f9;
  padding: 10px;
  text-align: left;
}

.historyList table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.historyList button:hover {
  background-color: #d5a10e;
  color: #fff;
}

.descriptionInput {
  width: 100%;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.descriptionInput:hover {
  border-color: #ddd;
}

.descriptionInput:focus {
  border-color: #d5a10e;
  outline: none;
}
